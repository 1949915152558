.mobile-header-container {
  height: 80px !important;
  background: #FFF !important;
}

.ndovu-logo-image {
  margin-top: -10px !important;
}

.mobile-profile-avatar {
  margin-top: -8px !important;
}
