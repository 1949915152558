.user-settings {
  font-weight: 500 !important;
  font-size: 22px !important;
  color: #0071ce !important;
}

.user-info {
  color: #0071ce !important;
  font-weight: 500 !important;
  margin-bottom: 5px !important;
}

.powered-by-container {
  background-color: #ffffff;
  bottom: 5px !important;
  position: absolute;
  width: 251px;
  height: 53px;
  margin-left: 7px;
}

.first-name {
  color: #0071ce !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  cursor: initial !important;
}

.active-menu {
  background-color: #0071ce !important;
  color: rgba(255, 255, 255, 1) !important;
}

.active-right-menu-link {
  color: rgba(255, 255, 255, 1) !important;
}

.on-hover {
  background-color: #0071ce !important;
  color: rgba(255, 255, 255, 1) !important;
}

.active {
  color: rgba(255, 255, 255, 1) !important;
}

.user-avatar {
  margin-top: -10px !important;
}
