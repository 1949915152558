.aside-menu-container {
  background-color: rgba(217, 234, 248, 1) !important;
}

.dashboard-link {
  background-color: rgba(217, 234, 248, 1) !important;
  padding: 15px !important;
  border-bottom: 0.5px solid rgba(0, 113, 206, 0.25);
}

.menu-text-color {
  color: #0071ce !important;
  font-size: 16px !important;
  margin-left: 15px !important;
}

.padding-bottom {
  padding: 15px !important;
  border-bottom: 0.5px solid rgba(0, 113, 206, 0.25);
}

.active-menu-item {
  background-color: #0071ce !important;
  color: rgba(255, 255, 255, 1) !important;
}

.hover-background:hover {
  background-color: #0071ce !important;
}

.hover-color {
  color: rgba(255, 255, 255, 1) !important;
}
